import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useSnackbar } from 'notistack';
import { Accordion, AccordionDetails, CircularProgress } from '@mui/material';
import LocationOnTwoToneIcon from '@mui/icons-material/LocationOnTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import SmallFooter from 'src/components/Footer/SmallFooter';
import LoginModal from 'src/components/Header/LoginModal';
import AccordionInfo from './components/AccordionInfo';
import OrderCard from './components/OrderCard';
import Help from './components/Help';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import Check from '../Van/components/item/CheckIcon';
import { IOrderForClient } from 'src/models/IOrder';
import './styles.scss';

const ClientPage: FC = () => {

  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { getClient, errorLogin, clearClientResult } = useActions();
  const {
    client: { client, orderList, isLoading, result },
    driver: { driver }
  } = useTypedSelector((state) => state);
  const resultCancel = useTypedSelector((state) => state.cancelOrder.result);
  const token = localStorage.getItem("guest-token");

  const [openLogin, setOpenLogin] = useState(false);
  const [paidOrders, setPaidOrders] = useState<IOrderForClient[]>([]);
  const [leadsOrders, setLeadsOrders] = useState<IOrderForClient[]>([]);

  useEffect(() => {
    if (token) {
      const { id } = jwtDecode(token!) as { [key: string]: any };
      if (id) getClient(id);
      else {
        errorLogin();
        navigate('/');
      }
    } else {
      errorLogin();
      navigate('/');
    }
  }, [token]);

  useEffect(() => {
    if (orderList?.length) {
      const paids = orderList!.filter((order) => order.status !== 'leads');
      paids.length && setPaidOrders(paids);
      const leads = orderList!.filter((order) => order.status === 'leads');
      leads.length && setLeadsOrders(leads);
    }
  }, [orderList, result.dates]);

  useEffect(() => {
    if (token) {
      const { id } = jwtDecode(token!) as { [key: string]: any };
      if (id) {
        (result.update || result.order || resultCancel || driver) && (
          getClient(id)
        );
      } else {
        errorLogin();
        navigate('/');
      }
    } else {
      errorLogin();
      navigate('/');
    }
  }, [result.update, result.order, resultCancel, driver]);

  useEffect(() => {
    result.pay && (
      window.open(`${result!.pay}`, '_blank', 'noopener,noreferrer'),
      clearClientResult()
    );
  }, [result.pay]);

  useEffect(() => {
    result.receipt
      && enqueueSnackbar(
        result.receipt,
        { variant: 'success' }
      );
    clearClientResult();
  }, [result.receipt]);

  useEffect(() => {
    if (result.dates) {
      if (result.dates === 200) {
        enqueueSnackbar(
          'Dates updated successfully',
          { variant: 'success' }
        );
      } else if (result.dates === 400) {
        enqueueSnackbar(
          'This van is not available on the dates you selected.',
          { variant: 'error' }
        );
      }
      clearClientResult();
    }
  }, [result.dates]);

  useEffect(() => {
    if (result.doc?.base64) {
      if (result.doc.fileType === 'application/pdf') {
        const pdfWindow = window.open('');
        pdfWindow!.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(result.doc?.base64) + "'></iframe>"
        );
      } else {
        const cw = window.open();
        cw!.document.write(
          `<img src="data:image/png;base64, ${result.doc?.base64}"/>`
        );
      }
    }
  }, [result.doc]);

  return (
    <>
      <div className='header'>
        <SmallHeader />
      </div>
      <div className='client'>
        {isLoading.get ? (
          <div className='loading-container'>
            <CircularProgress size={100} />
          </div>
        ) : (
          <>
            {(orderList?.length && client) ? (
              <div className='client__orders'>
                {paidOrders.length ? (
                  <div>
                    <h3>Upcoming Trip:</h3>
                    <h4 className='client__orders__subtitle'>
                      See All Trips Below
                    </h4>
                    {paidOrders.map((order, key) => (
                      <Accordion key={key}>
                        <AccordionInfo order={order} status={order.status} />
                        <AccordionDetails>
                          <OrderCard order={order} />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                ) : <></>}
                {leadsOrders.length ? (
                  <div>
                    <h3>Quotes:</h3>
                    <h4 className='client__orders__subtitle'>
                      Not A Booking
                    </h4>
                    {leadsOrders.map((order, key) => (
                      <Accordion key={key}>
                        <AccordionInfo order={order} status='quoted' />
                        <AccordionDetails>
                          <OrderCard order={order} />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                ) : <></>}
              </div>
            ) : (
              token && (
                <div className='client__no-booking'>
                  <div className='client__no-booking__header'>
                    <h2>No bookings found for {client?.email}.</h2>
                  </div>
                  <div className='client__no-booking__text'>
                    <p>
                      <b>Reasons could be:</b><br />
                      A typo in the email while booking.<br />
                      Check a different email address&nbsp;
                      <span onClick={() => setOpenLogin(true)}>
                        now
                      </span>.<br />
                      You may not have a booking yet.<br />
                      Contact us&nbsp;
                      <a
                        href='https://www.sprinter-rentals.com/contact'
                        target='_blank'
                      >
                        here
                      </a>
                      &nbsp;to get help.<br />
                    </p>
                  </div>
                </div>
              )
            )}
          </>
        )}
        <div className='client__info'>
          <div className='client__info__main'>
            <div className='client__info__main__container'>
              <div className='client__info__main__item'>
                <LocationOnTwoToneIcon />
                <h5>Address serve as pick-up & drop-off locations only.</h5>
              </div>
              <div className='client__info__main__item'>
                <CheckCircleTwoToneIcon />
                <h5>There is no staff on site and no vans are parked here.</h5>
              </div>
            </div>
          </div>
          <div className='client__info__secondary'>
            <div className='client__info__secondary__container'>
              <div className='client__info__secondary__item'>
                <Check />
                <h5>Pick a convinient address or get your van delivered.</h5>
              </div>
              <div className='client__info__secondary__item'>
                <Check />
                <h5>Pick-up & drop-off info is in your confirmation email.</h5>
              </div>
              <div className='client__info__secondary__item'>
                <Check />
                <h5>Get your keys after the online check-in process.</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SmallFooter />
      <Help />
      <LoginModal open={openLogin} onClose={() => setOpenLogin(false)} />
    </>
  );
};

export default ClientPage;
